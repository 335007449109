<template>
  <div class="">
    <div class="flex justify-between items-center mb-6">
      <div>
        <h1 class="text-4xl">Branches</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>

      <router-link
        to="/branches/create"
        class="
          border
          px-6
          py-2
          rounded-lg
          text-sm
          flex
          justify-center
          items-center
          hover:bg-gray-100
        "
      >
        Add New Branch
        <i class="bx bxs-plus-circle pl-1"></i>
      </router-link>
    </div>

    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow
              overflow-hidden
              border-b border-gray-200
              sm:rounded-lg
            "
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Name / Address
                  </th>

                  <!-- <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Total Sales
                  </th> -->

                  <!-- <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Total Commission
                  </th> -->

                  <th
                    scope="col"
                    class="
                      px-6
                      py-3
                      text-left text-xs
                      font-medium
                      text-gray-500
                      uppercase
                      tracking-wider
                    "
                  >
                    Status
                  </th>

                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only"></span>
                  </th>
                </tr>
              </thead>
              <tbody v-if="branches" class="bg-white divide-y divide-gray-200">
                <tr v-for="(branch, i) in branches.data" :key="i">
                  <td class="px-3 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ branch.name }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ branch.address }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <!-- <td class="px-6 py-4 whitespace-nowrap">
                    GMD <b>{{ formatPrice(branch.totalsales) }}</b>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    GMD <b>{{ formatPrice(branch.totalcommission) }}</b>
                  </td> -->

                  <td class="px-6 py-4 whitespace-nowrap">
                    <span
                      class="
                        px-2
                        inline-flex
                        text-xs
                        leading-5
                        font-semibold
                        rounded-full
                        bg-green-100
                        text-green-800
                      "
                    >
                      Active
                    </span>
                  </td>

                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-right text-sm
                      font-medium
                    "
                  >
                    <router-link
                      :to="`/branch/${branch.id}`"
                      class="text-indigo-600 hover:text-indigo-900"
                      >View
                    </router-link>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>

            <div class="border-t" v-if="branches">
              <pagination :data="branches" :midSize="2" @clicked="loadData">
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pagination from "../../components/Pagination";

import { mapGetters } from "vuex";
import checkPermissionExist from "../../mixins/checkPermissionExist";

// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",
  components: { Pagination },

  layout: "default",

  data: () => ({
    branches: null,
  }),

  mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),

  created() {
    var _this = this;
    _this.loadData(1);

    if (!this.checkPermissionExist(this.user.data, "branchs-read")) {
      this.$router.go(-1);
      return false;
    }
  },

  methods: {
    async loadData(pageNo, filter) {
      // Submit the form
      if (filter) {
        // filter = filter;
        this.searching = true;
      } else {
        filter = "";
        this.searching = false;
      }

      try {
        const { data } = await axios.get(
          `/branches?page=${pageNo}&filter=${filter}`
        );

        console.log(data);

        this.branches = data;
        this.searching = false;

        // console.log("The current page is: ", this.agents.meta.current_page);

        return true;
      } catch (e) {
        console.log("Error");
        this.searching = false;
        return false;
      }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
